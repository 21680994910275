/*
Basic application card found on the Application page.
Props: Title, Subtitle, Icon, Link, and LinkAction
Icons use Material font icons: https://material.io/resources/icons/?style=baseline
 */

import React from 'react';
import {Card, CardHeader, CardActions, Button, CssBaseline, Grid, makeStyles} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material/styles";
import ApplicationCardStyles from "../../styles/jss/components/common/ApplicationCardStyles";
import withRouter from '../../helpers/withRouter';
import NewProjectDialog from "./NewProjectDialog";
import { useNavigate  } from "react-router-dom";

const useStyles = makeStyles(ApplicationCardStyles);

const ApplicationCard = props => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const { title, subtitle, icon, link, actionText, history, showDialog, dialogOptions} = props;
    const navigate = useNavigate();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return(
        <Grid xs={12} md={6} lg={4} item className={classes.rootContainer}>
            <CssBaseline/>
            {showDialog &&
            <NewProjectDialog
                open={open}
                setOpen={setOpen}
                handleClose={() => handleClose()}
                title={dialogOptions.title}
                description={dialogOptions.description}
                inputs={dialogOptions.inputs}
                postUrl={dialogOptions.postUrl}
                app={dialogOptions.app}
                updateData={() => console.log('New project created.')}
                navigate={navigate}
            />
            }
            <Card className={classes.cardContainer}>
                <CardHeader
                    className={classes.headerRoot}
                    avatar={
                        <Icon className={classes.icon} color={theme.palette.grey[3]}>
                            {icon}
                        </Icon>
                    }
                    title={title}
                    subheader={subtitle}
                    classes={{title: classes.cardHeader}}
                    onClick={() => navigate(link)}
                />
                <CardActions className={classes.linkActionContainer}>
                    {/*Add card action function logic if Nam confirms current mockup*/}
                    <Button
                        size="medium"
                        variant={'text'}
                        classes={{root: classes.linkActionText}}
                        onClick={() => handleOpen()}
                    >
                        {actionText}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

ApplicationCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    actionText: PropTypes.string,
    showDialog: PropTypes.bool
};

ApplicationCard.defaultProps = {
    title: "Application Title",
    subtitle: "Subtitle",
    link: '/',
    icon: 'apps',
    actionText: '+ New Project',
    showDialog: false
};

export default withRouter(ApplicationCard);