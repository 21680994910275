import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FuseHeader from "./FuseHeader";
import withRouter from "../../../../helpers/withRouter";
import AnalysisDetails from "./LeftMenuItems/AnalysisDetails";
import { NotificationManager } from "react-notifications";
import FuseBodyGeometry from "./LeftMenuItems/FuseBodyGeometry";
import { setNestedValue, checkForData } from "../../../../helpers";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import ContentLoading from "../../../common/ContentLoading";
import ActivityLog from "./LeftMenuItems/ActivityLog";
import { fetchAuthSession } from 'aws-amplify/auth';
import { cfg } from "../../../../config";
import axios from "axios";
import moment from "moment";
import * as _ from 'lodash';
import StringerSection from "./StringerSection";
import LoadcaseSection from "./LoadcaseSection";
import { useParams } from "react-router-dom";

const FuseAnalysis = (props) => {
  const params = useParams();

  const [data, setData] = React.useState({});
  const [lock, setLock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [project, setProject] = React.useState({
    projectName: "",
    aircraft: "",
    projectID: 1,
    refs: [],
  });

  React.useEffect(() => {
    fetchData()
      .then(() => setIsLoading(false))
      .catch((e) => console.log(e));
  }, []);

  const saveDraft = (auto = false) => {
    setIsSaving(true);

    let newData = { ...data };

    console.log(newData)

    if (newData.name === lock.name) {
      delete newData.name;
    }
    delete newData.activityLog;
    delete newData.files;

    fetchAuthSession().then((session) => {
      axios
        .patch(
          `${cfg.apiUrl}/app/fuse/analysis/${params.projectId}/${params.id}`,
          {
            ...newData,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          let tempLock = { ...lock };
          tempLock.name = data.name;
          setLock(tempLock);

          if (resp.status === 200) {
            console.log("Draft Saved.");
            NotificationManager.success(`Data saved succesfully`, 'SUCCESS');
          } else {
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              NotificationManager.error(
                err.response.data.error.message,
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
          } else if (err.request) {
            NotificationManager.error(
              "An error has occurred, please try again.",
              "Error"
            );
          } else {
            NotificationManager.error(
              "An error has occurred, please try again.",
              "Error"
            );
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    });
  };


  const fetchData = async () => {
    // Axios logic for fetching data pertaining to analysis ID
    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/fuse/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              const newData = {
                name: resp.data.name,
                args: {
                  frameSpacing: checkForData(resp, "data.args.frameSpacing", ""),
                  modulusOfElasticity: checkForData(resp, "data.args.modulusOfElasticity", ""),
                  bodyStation: checkForData(resp, "data.args.bodyStation", ""),
                  // pressure: checkForData(resp, "data.args.pressure", ""),
                  pressureFactor: checkForData(resp, "data.args.pressureFactor", ""),
                  referenceWaterline: checkForData(resp, "data.args.referenceWaterline", ""),
                },
                data: checkForData(resp, "data.data",
                  {
                    stringers: [],
                    loadcases: []
                  }
                ),
                activityLog: [
                  {
                    title: "Date Created",
                    content: resp.data.created,
                  },
                  {
                    title: "Last Update",
                    content: resp.data.modified,
                  },
                ],
                results: checkForData(resp, "data.results", {}),
                status: resp.data.status,
                runDate: resp.data.runDate ? moment(resp.data.runDate).format("ll") : "",
              };

              setProject(resp.data.project);
              setData(JSON.parse(JSON.stringify(newData)));
              setLock(JSON.parse(JSON.stringify(newData))); // needed to avoid "name already exist" error when saving
              resolve();
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
            reject(e);
          });
      });
    });
  };

  /*
    Updates the current state.data.
     */
  const updateData = (value, objectLocation, forceUpdate = false) => {
    let newData = _.set({ ...data }, objectLocation, value) //setNestedValue({ ...data }, objectLocation, value);
    setData(newData);
  };

  const runAnalysis = async () => {
    setIsLoading(true);
    setIsRunning(true);

    await saveDraft(true);

    console.log("... sleeping for 1 second");
    await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/fuse/analyze`,
          {
            id: params.id,
            projectId: params.projectId,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          if (resp.status === 200) {
            await fetchData();
            setIsLoading(false);
          } else {
            NotificationManager.warning(
              "Something went wrong, please check the console.",
              "WARNING"
            );
          }
        })
        .catch(async (e) => {
          if (e.response || e.request) {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          } else {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          }
          console.log(e);
          await fetchData();
          setIsLoading(false);
        })
        .finally(async (resp) => {
          setIsRunning(false);
        });
    });
  };

  const pasteAnalysis = async () => {
    const copied = JSON.parse(window.sessionStorage.getItem("copyProject"));
    if (!copied) return;
    if (copied.args.aircraft === data.args.aircraft) {
      let name = data.name;
      copied.name = name;
      setData(copied);
      setLock(copied);
      window.sessionStorage.removeItem("copyProject");
    } else {
      NotificationManager.warning(
        "Cannot copy analysis. MissionMix/Aircraft mismatch.",
        "FAILED"
      );
    }
  };

  // Stringers

  const addStringerRow = async () => {
    let stringerSide = {
      'wl': 0,
      'bl': 0,
      'skin': 0,
      'stringerArea': 0,
      'deltaArea': 0,
      'efc': 0,
      'efs': 0,
      'eft': 0,
      'tanA': 0,
      'tanB': 0,
      'K': 0
    }
    let newRow = {
      left: { ...stringerSide },
      right: { ...stringerSide }
    };
    let newData = { ...data };
    newData.data.stringers.push(newRow);
    setData(newData);
    console.log(newData)
  };

  const deleteStringers = async (rowIndexes) => {
    let newData = { ...data };
    newData.data.stringers = newData.data.stringers.filter((stringer, id) => {
      return !rowIndexes.includes(id);
    });
    setData(newData);
  }

  // Loadcases

  const addLoadcaseRow = async () => {
    let stringerSide = {
      'id': 0,
      'vz': 0,
      'my': 0,
      'vy': 0,
      'mz': 0,
      'mx': 0,
      'pressure': 0
    }
    let newRow = stringerSide
    let newData = { ...data };
    newData.data.loadcases.push(newRow);
    setData(newData);
  };

  const deleteLoadcases = async (rowIndexes) => {
    let newData = { ...data };
    newData.data.loadcases = newData.data.loadcases.filter((loadcase, id) => {
      return !rowIndexes.includes(id);
    });
    setData(newData);
  }

  return !isLoading ? (
    <div>
      <FuseHeader
        projectID={project.projectID}
        data={data}
        status={data.status}
        isSaving={isSaving}
        isRunning={isRunning}
        runButtonDisabled={data.args.missionMix === "" || data.args.gagCycleType === ""}
        functions={{
          updateData: updateData,
          saveDraft: saveDraft,
          submit: runAnalysis,
          paste: pasteAnalysis,
        }}
      />
      <Grid container spacing={2} style={{ paddingBottom: "8px" }}>
        <Grid item md={7}>
          <AnalysisDetails
            refs={project.refs}
            name={data.name}
            updateData={updateData}
            project={project}
            run={data.runDate}
            status={data.status}
          />
        </Grid>
        <Grid item md={5}>
          <ActivityLog data={data} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <FuseBodyGeometry
            status={data.status}
            isSaving={isSaving}
            isRunning={isRunning}
            data={data}
            updateData={updateData}
            saveDraft={saveDraft}
            refresh={fetchData}
            aircraft={project.aircraft?.toUpperCase() ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <StringerSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addStringerRow={addStringerRow}
            deleteStringers={deleteStringers}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <LoadcaseSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addLoadcaseRow={addLoadcaseRow}
            deleteLoadcases={deleteLoadcases}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <ContentLoading />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ASPECStyles, { withTheme: true })(
  withRouter(FuseAnalysis)
);
