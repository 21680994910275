export const cfg = {
  Auth: {
    Cognito: {
      userPoolClientId: '2aa1ii3mo8r72v5nerqsiv157n',
      userPoolId: 'us-east-1_bVkw6LnI5',
      mandatorySignIn: true,
      region: 'us-east-1',
      identityPoolId: 'us-east-1:544b86f5-9a37-4e07-9ac5-4c6781cff471',
    },
  },
  Storage: {
    S3: {
      region: 'us-east-1',
      bucket: 'aspec-data-prod',
      identityPoolId: 'us-east-1:544b86f5-9a37-4e07-9ac5-4c6781cff471',
      level: 'public',
      customPrefix: {
        public: ''
      },
    },
  },
  apiUrl:  process.env.REACT_APP_API_URL || 'https://gjqy2qdytd.execute-api.us-east-1.amazonaws.com/prod/',
  domain: 'aeronauticausa', // used to show 2 skin effectivity options on aeronauticausa.com (only 1 option on govCloud)
};