const basic_aircraft = [
  {
    value: 'Airbus A319',
    label: 'Airbus A319'
  },
  {
    value: 'Airbus A320',
    label: 'Airbus A320'
  }];
const premium_aircraft = [
  {
    value: 'Airbus A320 2005-2019 Usage',
    label: 'Airbus A320 2005-2019 Usage'
  },
  {
    value: 'Airbus A321',
    label: 'Airbus A321'
  },
  {
    value: 'Bizjet',
    label: 'Bizjet'
  },
  {
    value: 'Boeing 737-8',
    label: 'Boeing 737-8'
  },
  {
    value: 'Boeing 737-800',
    label: 'Boeing 737-800'
  },
  {
    value: 'Boeing 737-900ER',
    label: 'Boeing 737-900ER'
  },
  {
    value: 'Boeing 767',
    label: 'Boeing 767'
  },
  {
    value: 'Boeing 777',
    label: 'Boeing 777'
  },
  {
    value: 'CH47',
    label: 'CH47'
  },
  {
    value: 'CRJ100',
    label: 'CRJ100'
  },
  {
    value: 'DC-6',
    label: 'DC-6'
  },
  {
    value: 'OV10A Patrick AFB',
    label: 'OV10A Patrick AFB'
  },
  {
    value: 'OV10A George AFB',
    label: 'OV10A George AFB'
  },
  {
    value: 'P2V5 Empennage',
    label: 'P2V5 Empennage'
  },
  {
    value: 'P2V5 Firefighting',
    label: 'P2V5 Firefighting'
  },
  {
    value: 'P2V5 Maritime Patrol',
    label: 'P2V5 Maritime Patrol'
  },
  {
    value: 'P3A Firefighting',
    label: 'P3A Firefighting'
  },
  {
    value: 'P3A Maritime Patrol',
    label: 'P3A Maritime Patrol'
  },
  {
    value: 'T28D',
    label: 'T28D'
  }
];
const all_aircraft = basic_aircraft.concat(premium_aircraft);

export {basic_aircraft, all_aircraft};