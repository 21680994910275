import React from "react";
import ApplicationCard from "../components/common/ApplicationCard";
import axios from "axios";
import { cfg } from "../config";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Grid } from "@mui/material";

import { all_aircraft, basic_aircraft } from "../data/aircraft";

const Applications = () => {
  const [allowedAircrafts, setAllowedAircrafts] = React.useState([]);
  const [allowedApplications, setAllowedApplications] = React.useState([]);

  const setOrgData = (org) => {
    const isSubscriptionPremium = org.billing.plan === "premium";

    // Set allowed applications
    let allowedApplications = ["ASPEC LOADSGEN", "FUSELOADS", "WINGBOX", "RINGFRAME", "WINGSPAN"];
    const includedApplications = org?.data?.included_apps; // Get included_apps if it exists (backward compatibility)
    if (includedApplications) {
      allowedApplications = allowedApplications.filter(app => includedApplications.includes(app)); // Override allowed applications
    }
    // Set allowed aircrafts
    setAllowedApplications(allowedApplications);

    // Set allowerd Aspec aircrafts
    let allowedAircrafts = isSubscriptionPremium ? all_aircraft : basic_aircraft;
    // Exclude aircrafts for org
    const excludedAircrafts = org?.data?.excluded_aircrafts; // Get excluded_aircrafts if it exists (backward compatibility)
    if (excludedAircrafts) {
      allowedAircrafts = allowedAircrafts.filter(a => !excludedAircrafts.includes(a.value));
    }
    // Set allowed aircrafts
    setAllowedAircrafts(allowedAircrafts);
  }

  // Update input variants when missionMix is changed.
  React.useEffect(() => {
    // Get org
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          setOrgData(resp.data)
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }, []);

  const allApplications = [
    {
      title: "Fatigue Spectrum Analysis",
      subtitle: "ASPEC LOADSGEN",
      icon: "airplanemode_active",
      link: "/apps/aspec-loadsgen",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Fatigue Spectral Analysis project, please input a project name and select an aircraft.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          },
          {
            type: "select",
            label: "Aircraft Type",
            id: "aircraft",
            options: allowedAircrafts,
          },
        ],
        postUrl: "app/aspec/project",
        app: "aspec",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Fuselage Unit Beam Solver",
      subtitle: "FUSELOADS",
      icon: "airplanemode_active",
      link: "/apps/fuseloads",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Fuselage Unit Beam Solver project, please input a project name and select an aircraft.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          },
          {
            type: "text",
            label: "Aircraft",
            id: "aircraft",
          },
        ],
        postUrl: "app/fuse/project",
        app: "fuse",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Multi-bay Box Beam Analysis",
      subtitle: "WINGBOX",
      icon: "airplanemode_active",
      link: "/apps/wingbox",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Box Beam Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/boxbeam/project",
        app: "boxbeam",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Ring Frame Analysis",
      subtitle: "RINGFRAME",
      icon: "airplanemode_active",
      link: "/apps/ringframe",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Ring Frame Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/ring/project",
        app: "ring",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    },
    {
      title: "Wingspan Analysis",
      subtitle: "WINGSPAN",
      icon: "airplanemode_active",
      link: "/apps/wingspan",
      showDialog: true,
      dialogOptions: {
        title: "New Project",
        description:
          "To create a new Wingspan Analysis project, please input a project name.",
        inputs: [
          {
            type: "text",
            label: "Project Name",
            id: "name",
          }
        ],
        postUrl: "app/wingspan/project",
        app: "wingspan",
        confirmAction: () => {
          console.log("confirmed");
        },
      },
    }
  ];

  return (
    <div>
      <Grid container spacing={3}>
        {allApplications.filter(app => allowedApplications.includes(app.subtitle)).map((app, idx) => (
          <ApplicationCard
            key={idx}
            showDialog={app.showDialog}
            title={app.title}
            subtitle={app.subtitle}
            icon={app.icon}
            dialogOptions={app.dialogOptions}
            link={app.link}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Applications;
